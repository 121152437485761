/**
 * LangSwitcher
 * 
 * @Author: Focci
 * @Date: 2024-01-10 15:56:26
 * @Last Modified by: Focci
 * @Last Modified time: 2024-01-10 15:56:26
 */

// import PropTypes from 'prop-types'
import HoverDropdown from '@comp/HoverDropdown'
import useApp from '@hook/useApp'
import useLocaleSwitch from '@hook/useLocaleSwitch'
import SVGEarth from '@img/earth.svg'
import clsx from 'clsx'
import { langOpts } from '@lib/utils'
import { useCallback } from 'react'

function LangSwitcherTrigger({
  className = 'text-white text-f.6',
  showIcon = true
}) {
  const { langLabel } = useLocaleSwitch()

  return (
    <div
      className={clsx([
        'flex items-center gap-x-1 cursor-pointer',
        className
      ])}
    >
      {showIcon && <SVGEarth width=".9rem" />}
      {langLabel}
    </div>
  )
}

function LangSwitcherItem({ lang, item, onClick = () => { } }) {
  const handleClick = useCallback(() => onClick(item), [onClick, item])

  return (
    <div
      role="button"
      onMouseDown={handleClick}
      onKeyPress={handleClick}
      tabIndex={0}
      className={clsx([
        'px-6 py-1 text-center whitespace-nowrap',
        lang === item.value ? 'text-primary' : 'hover:bg-cf0 cursor-pointer',
      ])}
    >
      {item.label}
    </div>
  )
}

export default function LangSwitcher({
  triggerProps
}) {
  const { lang, switchLanguage } = useLocaleSwitch()
  const { isMobile } = useApp()

  const handleClick = useCallback((item) => {
    if (item.value !== lang) {
      switchLanguage(item.value)
      localStorage.setItem('set_lang', 1)
    }
  }, [lang, switchLanguage])

  return (
    <HoverDropdown
      trigger={<LangSwitcherTrigger {...triggerProps} />}
      hoverType={isMobile ? 'click' : 'hover'}
    >
      <div className="bg-white shadow-large rounded-md py-2">
        {langOpts.map((item) => (
          <LangSwitcherItem
            key={item.value}
            lang={lang}
            item={item}
            onClick={handleClick}
          />
        ))}
      </div>
    </HoverDropdown>
  )
}

// LangSwitcher.propTypes = {
//   className: PropTypes.string,
// }
